import Solution from '@/components/Solution/solution.vue';
/**
 * 设备列表显示字段
 * @type {{Array}
 */
export const deviceListColumns = [
    // {
    //     title: '设备编号',
    //     slot:'设备编号',
    //     width:100
    // },
    {
        title: '设备信息',
        slot:'设备信息',
        width:200
    },
    // {
    //     title: '设备简介',
    //     slot:'设备简介',
    //     minWidth:100,
    //     align: 'center'
    // },
    {
        title: '技术参数',
        slot:'技术参数',
        minWidth:100,
        align: 'center'
    },
    // {
    //     title: '工作原理',
    //     slot:'工作原理',
    //     minWidth:100,
    //     align: 'center'
    // },
    // {
    //     title: '使用维护',
    //     slot:'使用维护',
    //     minWidth:100,
    //     align: 'center'
    // },
    {
        title: '故障解析',
        slot:'故障解析',
        minWidth:100,
        align: 'center'
    },
    {
        title: '机械图册',
        slot:'机械图册',
        minWidth:100,
        align: 'center'
    },
    {
        title: '电气图册',
        slot:'电气图册',
        minWidth:100,
        align: 'center'
    },
    // {
    //     title: '结构部件',
    //     slot:'结构部件',
    //     minWidth:100,
    //     align: 'center'
    // },
    {
        title: '文件资料',
        slot:'文件资料',
        minWidth:100,
        align: 'center'
    },
    {
        title: '操作',
        slot:'操作',
        width: 160,
        align: 'center',
        fixed: 'right',
    },
]
/**
 * 结构部件显示字段
 * @type {*[]}
 */
export const structuralElementColumns = [
    {
        title: '序号',
        slot:'序号',
        width: 160,
        align: 'center'
    },
    {
        title: '部件名称',
        key:'partsName',
    },
    {
        title: '部件图片',
        slot: '部件图片',
        width:300,
        align: 'center',
    },
    {
        title: '上传时间',
        width:200,
        key:'uploadTime',
    },
]
/**
 * 资料文件显示字段
 * @type {{Array}
 */
export const deviceDataColumns = [
    {
        title: '序号',
        slot:'序号',
        width: 60,
        align: 'center'
    },
    {
        title: '文件名称',
        slot:'文件名称',
    },
    {
        title: '文件大小',
        width:100,
        slot:'文件大小',
    },
    {
        title: '上传人',
        width:100,
        key:'uploadUserName',
    },
    {
        title: '上传时间',
        width:200,
        key:'uploadTime',
    },
    {
        title: '操作',
        width:150,
        align: 'center',
        slot:'操作',
        // fixed: 'right',
    },
]

/**
 * 资料文件视频显示字段
 * @type {{Array}
 */
export const deviceDataVideoColumns = [
    {
        title: '序号',
        slot:'序号',
        width: 60,
        align: 'center'
    },
    {
        title: '文件名称',
        slot:'文件名称',
    },
    {
        title: '文件大小',
        slot:'文件大小',
    },
    {
        title: '上传人',
        key:'uploadUserName',
    },
    {
        title: '上传时间',
        width:200,
        key:'uploadTime',
    },
    {
        title: '操作',
        width:150,
        align: 'center',
        slot:'操作',
    },
]

/**
 * 故障分析显示字段
 * @type {{Array}
 */
export const faultDataColumns = [
    {
        title: '序号',
        slot:'序号',
        width: 100,
        align: 'center'
    },
    {
        title: '故障描述',
        key:'describe',
        minWidth:200,
    },
    {
        title: '故障代码',
        slot:'故障代码',
    },
    {
        title: '解决方案',
        type: 'expand',
        align: 'center',
        width:200,
        render: (h, params) => {
            return h(Solution, {
                props: {
                    reasons: params.row.reasons,
                    id:params.row.id
                },

            })
        }
    },
]

/**
 * 可能的原因显示字段
 * @type {{Array}
 */
export const reasonDataColumns = [
    {
        title:' ',
        width:80,
    },
    {
        title: '序号',
        slot:'序号',
        width:100,
        align: 'center'
    },
    {
        title: '可能原因',
        key:'reason',
    },
    {
        title: '操作',
        slot:'操作',
        width:300,
        align: 'center',
    },
]

/**
 * 设备标签页数据
 * @type {{}[]}
 */
export const deviceTabs = [
    {
        label:6,
        value:'基础信息'
    },
    // {
    //     label:7,
    //     value:'设备简介'
    // },
    {
        label:9,
        value:'技术参数'
    },
    // {
    //     label:8,
    //     value:'工作原理'
    // },
    // {
    //     label:1,
    //     value:'使用维护'
    // },
    {
        label:5,
        value:'故障解析'
    },
    {
        label:2,
        value:'机械图册'
    },
    {
        label:3,
        value:'电气图册'
    },
    {
        label:0,
        value:'文件资料'
    }
]
export const parameterColumns = [
    {
        title: '名称',
        slot:'名称',
    },
    {
        title: '参数',
        slot:'参数',
    },
]
