<template>
    <div class="know-box">
        <div class="list-item" :key="'child1'+key" v-for="(deviceList,key) in deviceListArray">
            <div class="item-thead" :class="selectedMenuId && selectedMenuId[0] === deviceList.value && selectedMenuId[1] === ''?'item-thead-active':''" >
                <a href="javascript:">
                    <Icon  @click="deviceList.visible = !deviceList.visible" :type="deviceList.visible?'md-arrow-dropdown':'md-arrow-dropright'" class="icon" />
                    <p @click="onLinks(deviceList.value,'','')">{{ deviceList.label }}</p>
                </a>
            </div>
            <div class="item-body" v-if="deviceList.visible">
                <div class="item-child" :key="'child2'+key" v-for="(children,key) in deviceList.children">
                    <div class="child-thead" :class="selectedMenuId && selectedMenuId[2] === children.value?'child-thead-active':''" :style="children.children.length === 0?'padding-left:72px':''">
                        <a href="javascript:">
                            <Icon @click="children.visible = !children.visible" v-if="children.children.length !== 0" :type="children.visible?'md-arrow-dropdown':'md-arrow-dropright'" class="icon" />
                            <p @click="onLinks(deviceList.value,children.value,children.value)">{{ children.label }}</p>
                        </a>
                    </div>
                    <div class="child-body" v-if="children.visible">
                        <ul>
                            <li :key="'child3'+key" v-for="(child,key) in children.children" :class="selectedMenuId && selectedMenuId[2] === child.value?'child-body-active':''">
                                <a href="javascript:" @click="onLinks(deviceList.value,children.value,child.value)">
<!--                                    <Icon type="ios-document-outline" class="icon"/>-->
                                  <Icon type="ios-list-box-outline" class="icon"/>
                                    {{ child.label }}
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MenuKnowledge from './index'
export default MenuKnowledge
</script>
<style lang="less">
@import "index";
</style>
