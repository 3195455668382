<template>
    <div id="canvasBox" class="canvas-box" v-show="visibleScreenshot">
        <canvas id="definition" class="agora_video_definition"></canvas>
        <canvas
            id="canvas"
            class="agora_video_canvas"
        ></canvas>
        <div class="agora_video_mask"
             @touchstart="onMouseDown"
             @touchend="onMouseUp"
             @touchmove="onMouseMove"
             @mousedown="onMouseDown"
             @mouseup="onMouseUp"
             @mouseout="onMouseUp"
             @mousemove="onMouseMove"
             @click="onClickSwitch">
        </div>
    </div>
</template>
<script>
import $event, {EVENT_NAME} from "@/utils/event-emitter";
import {mapState} from "vuex";
import LineName from '@/components/LineName'
export default {
    name: "screenshot",
    computed: {
        ...mapState({
            state: state => state.userCenter.userInfo, //获取状态管理用户中心
        }),
    },
    props:{
        /**
         * 监听传过来的model值
         */
        value: {
            type: Boolean,
            default: false
        },
        color:{
            type: String,
            default:'#fda73e'
        },
        lineWidth:{
            type: Number,
            default:2
        },
        menuVisible:{
            type: Boolean,
        },
        videoScreenSwitch:{
            type:Boolean,
        },
        name:{
            type:String
        }
    },
    created() {
        $event.$on(EVENT_NAME.SUBSCRIBER_SCREENSHOT, (status) => {
            this.videoClientStatus = status
            this.screenshot('video','definition');
        });
        $event.$on(EVENT_NAME.SUBSCRIBER_DRAW, (status) => {
            this.videoClientStatus = status.videoClientStatus
            this.payScreenshot(status.url,'canvas')
        });
        $event.$on(EVENT_NAME.UNDO_DRAW, (uid) => {
            this.canvasUndo(uid)
        });
        $event.$on(EVENT_NAME.REMOVE_GRAFFITI, (uid) => {
            this.onRemoveGraffiti(uid)
        });
        $event.$on(EVENT_NAME.CANVAS_RESIZE, () => {
            console.log(this.lineData)
        });
        $event.$on(EVENT_NAME.MOUSE_DOWN, (obj) => {
            let mouseDown = {
                pageX:obj.pageX,
                pageY:obj.pageY,
                pagePX:obj.pagePX,
                pagePY:obj.pagePY,
                color:obj.color,
                thickness:obj.lineWidth
            }
            this.lineData.push({[obj.uid]:[mouseDown]})
        });
        $event.$on(EVENT_NAME.DRAWING_UP, (obj) => {
            LineName.info({
                x:obj.pageX,
                y:obj.pageY,
                color:obj.color,
                name:obj.lineName
            })
        });
        $event.$on(EVENT_NAME.EMPTY_LINE_DATA, () => {
            this.lastImg = []
            this.lineData = []
            this.position = 0
            let videoHandleId = document.getElementById('videoHandle')
            let videoImage = document.getElementById('canvas');
            let videoImageContext = videoImage.getContext( '2d' );
            let ratio = this.getPixelRatio(videoImageContext)
            videoImageContext.fillRect( 0, 0, videoHandleId.offsetWidth*ratio, videoHandleId.offsetHeight*ratio );
        });
        $event.$on(EVENT_NAME.PUBLISHER_DRAWING, (obj) => {
            this.drawLine(
                'canvas',
                obj.pageX,
                obj.pageY,
                obj.pagePX,
                obj.pagePY,
                obj.color,
                obj.thickness
            )
            let drawing = {
                pageX:obj.pageX,
                pageY:obj.pageY,
                pagePX:obj.pagePX,
                pagePY:obj.pagePY,
                color:obj.color,
                thickness:obj.thickness
            }
            this.lineData[this.lineData.length-1][obj.uid].push(drawing)
        });
    },
    data() {
        return {
            visibleScreenshot:this.value, //显示与隐藏
            drawing:false, //是否绘制
            current:{},
            lastImg:[],
            lineData:[],
            position:0,
            lineId:'',
            videoClientStatus:false, //视频功能状态 true:专家端 false:现场端
        }
    },
    methods:{
        onClickSwitch() {
          this.$emit('on-click-switch')
        },
        /**
         * 记录按下的事件
         * @param e
         * @returns {{x: number, y: number}}
         */
        onMouseDown(e) {
            if(!this.videoClientStatus || this.videoScreenSwitch){
                return
            }

            this.drawing = true;
            //记录按下点
            let p = this.getPoint(e);
            this.current.x = p.x;
            this.current.y = p.y;
            let videoImage = document.getElementById('canvas');
            let obj = {
                pageX:this.current.x,
                pageY:this.current.y,
                pagePX:p.x,
                pagePY:p.y,
                color:this.color,
                thickness:this.lineWidth,
                offsetWidth:videoImage.offsetWidth,
                offsetHeight:videoImage.offsetHeight,
                lineName:this.name
            }
            this.lineData.push({[this.state.uuid]:[obj]})
            this.$emit('on-mouse-down',obj)
        },
        /**
         * 记录抬起的事件
         * @param e
         */
        onMouseUp(e) {
            if(!this.videoClientStatus || this.videoScreenSwitch){
                return
            }
            if (!this.drawing) { return; }
            this.drawing = false;
            //绘制结束点
            let p = this.getPoint(e);
            LineName.info({
                x:p.x,
                y:p.y,
                color:this.color,
                name:this.name
            })
            this.drawLine('canvas',this.current.x, this.current.y, p.x, p.y, this.color, this.lineWidth);
            let videoImage = document.getElementById('canvas');
            let obj = {
                pageX:this.current.x,
                pageY:this.current.y,
                pagePX:p.x,
                pagePY:p.y,
                color:this.color,
                thickness:this.lineWidth,
                offsetWidth:videoImage.offsetWidth,
                offsetHeight:videoImage.offsetHeight,
                lineName:this.name
            }
            this.lineId = '';
            this.$emit('on-draw-line',obj)
            this.$emit('on-draw-up',obj)
        },
        /**
         * 记录移动的事件
         * @param e
         */
        onMouseMove(e) {
            if(!this.videoClientStatus || this.videoScreenSwitch){
                return
            }
            if (!this.drawing) { return; }
            let p = this.getPoint(e);
            //移动绘制
            this.drawLine('canvas',this.current.x, this.current.y, p.x, p.y, this.color, this.lineWidth);
            let videoImage = document.getElementById('canvas');
            let obj = {
                pageX:this.current.x,
                pageY:this.current.y,
                pagePX:p.x,
                pagePY:p.y,
                color:this.color,
                thickness:this.lineWidth,
                offsetWidth:videoImage.offsetWidth,
                offsetHeight:videoImage.offsetHeight,
            }
            this.$emit('on-draw-line',obj)
            this.current.x = p.x;
            this.current.y = p.y;
            this.lineData[this.lineData.length-1][ this.state.uuid].push(obj)
        },
        /**
         * 获取点坐标
         * @param e
         * @returns {{x: number, y: number}}
         */
        getPoint(e) {
            let videoImage = document.getElementById('canvas');
            let agoraRTC = document.getElementById('agoraRTC')
            if (e.touches && e.touches.length > 0) {
                let touch = e.touches[0];
                let menu = this.menuVisible?270:0
                return { x:(touch.pageX-menu-(agoraRTC.offsetWidth-videoImage.offsetWidth)/2)/(videoImage.offsetWidth) , y: (touch.pageY-41-(agoraRTC.offsetHeight-videoImage.offsetHeight)/2)/(videoImage.offsetHeight) };
            }
            return { x: e.offsetX/videoImage.offsetWidth, y: e.offsetY/videoImage.offsetHeight};
        },
        /**
         * 绘制
         * @param x0 路径的目标位置的 x 坐标
         * @param y0 路径的目标位置的 y 坐标
         * @param x1 路径的目标位置的 x 坐标
         * @param y1 路径的目标位置的 y 坐标
         * @param color 线条颜色
         * @param width 线条宽度
         */
        drawLine(canvasId,x0, y0, x1, y1, color, width)  {
            let canvas = document.getElementById(canvasId);
            let context = canvas.getContext('2d');
            let lineWidth;
            switch (width){
                case 1:
                    lineWidth = 4;
                  break;
                case 2:
                    lineWidth = 6;
                  break;
                case 3:
                    lineWidth = 10;
                  break;
                case 4:
                    lineWidth = 16;
                  break;
            }
            // if(videoHandleId.offsetWidth*this.getPixelRatio(context))
            context.beginPath();
            context.moveTo(x0*canvas.width, y0*canvas.height);
            context.lineTo(x1*canvas.width, y1*canvas.height);
            context.strokeStyle = color;
            context.lineWidth = lineWidth;
            context.lineCap = "round";
            context.lineJoin = "round";
            context.stroke();
            context.closePath();
        },
        /**
         * 撤销动作
         */
        canvasUndo(uid) {
            let canvas = document.getElementById('canvas');
            let context = canvas.getContext('2d');
            context.putImageData(this.lastImg[0].value, 0, 0)

            for(let i in this.lineData){
                for(let key in this.lineData[i]){
                   if(key === uid){
                       this.position = i
                   }
                }
            }
            for(let name in this.lineData[this.position]){
                if(name === uid){
                    this.lineData.splice(this.position,1)
                }
            }
            for(let i in this.lineData){
                for(let value in this.lineData[i]){
                    for(let key in this.lineData[i][value]){
                        let obj = this.lineData[i][value][key]
                        this.drawLine('canvas',obj.pageX,obj.pageY,obj.pagePX,obj.pagePY,obj.color,obj.thickness)
                    }
                }
            }
        },
        /**
         * 清除动作
         */
        onRemoveGraffiti(uid){
            let canvas = document.getElementById('canvas');
            let context = canvas.getContext('2d');
            context.putImageData(this.lastImg[0].value, 0, 0)

            for(let i = 0;i<this.lineData.length;i++){
                for(let key in this.lineData[i]){
                    if(key === uid){
                        this.lineData.splice(i,1)
                        i--
                    }
                }
            }
            for(let i in this.lineData){
                for(let value in this.lineData[i]){
                    for(let key in this.lineData[i][value]){
                        let obj = this.lineData[i][value][key]
                        this.drawLine('canvas',obj.pageX,obj.pageY,obj.pagePX,obj.pagePY,obj.color,obj.thickness)
                    }
                }
            }
        },
        /**
         * 获取canvas应该放大的倍数
         */
        getPixelRatio(context) {
            let backingStore = context.backingStorePixelRatio
                || context.webkitBackingStorePixelRatio
                || context.mozBackingStorePixelRatio
                || context.msBackingStorePixelRatio
                || context.oBackingStorePixelRatio
                || context.backingStorePixelRatio || 1
            return (window.devicePixelRatio || 1)/backingStore
        },
        /**
         * 视频截图
         * @param video
         * @param canvas
         */
        screenshot(videoLabel,canvas) {
            let video = document.querySelector(videoLabel);
            let videoImage = document.getElementById(canvas);
            let videoHandleId = document.getElementById('videoHandle')
            let videoImageContext = videoImage.getContext( '2d');
            let ratio = this.getPixelRatio(videoImageContext)
            videoImage.width = videoHandleId.offsetWidth*ratio; // 实际渲染像素
            videoImage.height = videoHandleId.offsetHeight*ratio; // 实际渲染像素
            videoImage.style.width = `${videoHandleId.offsetWidth}px`
            videoImage.style.height = `${videoHandleId.offsetHeight}px`
            videoImageContext.imageSmoothingEnabled = false;
            videoImageContext.fillRect( 0, 0, videoHandleId.offsetWidth*ratio, videoHandleId.offsetHeight*ratio );
            videoImageContext.drawImage(video, 0, 0, videoHandleId.offsetWidth*ratio,videoHandleId.offsetHeight*ratio );
        },
        /**
         * 把url绘制到canvas上
         * @param canvas
         */
        payScreenshot(url,canvas){
            this.lastImg = []
            this.lineData = []
            this.position = 0;
            let videoImage = document.getElementById(canvas);
            let videoImageContext = videoImage.getContext( '2d' );
            let videoHandleId = document.getElementById('videoHandle')
            let ratio = this.getPixelRatio(videoImageContext)
            videoImage.width = videoHandleId.offsetWidth*ratio; // 实际渲染像素
            videoImage.height = videoHandleId.offsetHeight*ratio; // 实际渲染像素
            videoImage.style.width = `100%`
            videoImage.style.height = `100%`
            videoImageContext.imageSmoothingEnabled = false;
            let imageUrl = new Image();
            imageUrl.src = url
            imageUrl.crossOrigin = '';
            imageUrl.onload = () => {
                videoImageContext.fillRect( 0, 0, videoHandleId.offsetWidth*ratio, videoHandleId.offsetHeight*ratio );
                videoImageContext.drawImage(imageUrl, 0, 0, videoHandleId.offsetWidth*ratio, videoHandleId.offsetHeight*ratio );
                this.lastImg.push({'value':videoImageContext.getImageData(0, 0, videoHandleId.offsetWidth*ratio, videoHandleId.offsetHeight*ratio)});
                this.$emit('on-screenshot')
            }
        },
    },
    watch: {
        value(val) {
            this.visibleScreenshot = val;
        },
    },
    destroyed() {
        $event.$off([EVENT_NAME.SUBSCRIBER_SCREENSHOT,EVENT_NAME.PUBLISHER_DRAWING,EVENT_NAME.UNDO_DRAW,EVENT_NAME.REMOVE_GRAFFITI,EVENT_NAME.MOUSE_DOWN,EVENT_NAME.SUBSCRIBER_DRAW,EVENT_NAME.CANVAS_RESIZE])
    }
}
</script>

<style scoped>
</style>
