/**
 *  全局线条名称组件
 */
import Vue from 'vue/dist/vue.esm.js'
const defaults = {
    duration: 1000, // 显示的时间 ms
    animateTime: 1000, // 动画时间,表示这个组件切换show的动画时间
}
function getLineNameInstance (options) {
    let duration = defaults.duration
    let VueSign = Vue.extend({
        data() {
            return {
                x:options.x,
                y:options.y,
                VueColor:options.color,
                VueName:options.name
            }
        },
        template:`<div class="line-name" :style="'position: absolute;'+(VueColor === '#FFF100'?'color:#515a6e':'color:#fff')+';border-radius: 4px;padding:0 2px;z-index:104;background:'+VueColor+';'+(x>0.95?'right:'+(1-x)*100+'%':'left:calc('+(x*100)+'% + '+(y<0.05||y>0.95?'5px':'0px')+')')+';'+(y<0.05?'top:'+(y*100)+'%':'top:calc('+(y*100)+'% - 25px)')+''">{{ VueName }}</div>`
    })
    let newSign = new VueSign()
    let vm = newSign.$mount()
    let el = vm.$el
    document.getElementById('canvasBox').appendChild(el) // 把生成的提示的dom插入body中
    let t1 = setTimeout(() => {
        clearTimeout(t1)
        let t2 = setTimeout(() => {
            clearTimeout(t2)
            document.getElementById('canvasBox').removeChild(el) //从body中移除dom
            newSign.$destroy()
            vm = null // 设置为null，好让js垃圾回收算法回收，释放内存
        }, defaults.animateTime)
    }, duration)
}
export default {
    name:'LineName',
    info (options){
        if (!options) return
        getLineNameInstance(options)
    },
}
