/**
 * 发送指令
 * @type {{ enum }}
 * @type send command ↑
 */
export const SEND_COMMAND = {
    REQUEST_COOPERATION:'REQUEST_COOPERATION',//请求协作
    COOPERATION_USER:'COOPERATION_USER',//协作对方指令
    INVITE_USERS:'INVITE_USERS', //邀请用户
    ACCEPT:'ACCEPT', //接受邀请指令
    DECLINED:'DECLINED', //拒绝邀请
    CALL_HANG_UP:'CALL_HANG_UP', //呼叫挂断 主动挂断
    EXIT_SESSION:'EXIT_SESSION', //退出会话
    SEND_TEXT:'SEND_TEXT', //发送文本
    SEND_IMAGE:'SEND_IMAGE', //发送图片
    SEND_ACTION:'SEND_ACTION', //发送动作
    SCREEN_SHARING_OFF:'SCREEN_SHARING_OFF',//屏幕共享结束
    APPLY_SCREEN_SHARING:'APPLY_SCREEN_SHARING', //申请屏幕共享
    CAMERA_CONTROLLER_SET:'CAMERA_CONTROLLER_SET', //相机控制
    MICROPHONE_STATE_SET:'MICROPHONE_STATE_SET', //话筒状态设置
    LOGIN_SWITCH_ACTION:'LOGIN_SWITCH_ACTION', //回复登录切换指令
}

/**
 * 接收指令
 * receive instructions ↓
 * @type {{ enum }}
 */
export const RECEIVE_INSTRUCTIONS = {
    CALL_NOTIFICATION:'CALL_NOTIFICATION', //邀请通知
    CALL_RESULT:'CALL_RESULT', //邀请结果通知
    HANG_UP_NOTIFICATION:'HANG_UP_NOTIFICATION', //主动呼叫方挂断
    SESSION_OPEN:'SESSION_OPEN', //会话开启
    RESTORE_SESSION:'RESTORE_SESSION', //会话开启
    SESSION_CLOSE:'SESSION_CLOSE', //会话关闭
    MESSAGE_NOTIFICATION:'MESSAGE_NOTIFICATION', //消息通知
    ACTION_NOTIFICATION:'ACTION_NOTIFICATION', //收到动作
    INITIATIVE_CALLING:'INITIATIVE_CALLING', //主动呼叫中
    USERS_STATUS_CHANGE_NOTICE:'USERS_STATUS_CHANGE_NOTICE',//用户状态变更指令
    MEMBER_JOIN:'MEMBER_JOIN', //成员加入
    MEMBER_LEAVE:'MEMBER_LEAVE', //会话成员离开
    REFUSE:'REFUSE', //拒绝
    ACCEPT:'ACCEPT', //接受
    OFFLINE:'OFFLINE', //下线
    BUSY:'BUSY', //繁忙
    MULTI_DEVICE_LOGIN_NOTICE:'MULTI_DEVICE_LOGIN_NOTICE', //多终端登录提醒
    SCREEN_SHARING_NOTIFY:'SCREEN_SHARING_NOTIFY', //屏幕共享通知
    APPLY_SCREEN_SHARING_RESULT:'APPLY_SCREEN_SHARING_RESULT', //申请结果
    SCREEN_SHARING_RECOVER:'SCREEN_SHARING_RECOVER', //屏幕共享恢复
    CAMERA_CONFIG:'CAMERA_CONFIG', // 相机配置信息
    CAMERA_CONTROLLER_INFO:'CAMERA_CONTROLLER_INFO', //缩放通知
    MICROPHONE_STATE_CHANGE_NOTIFY:'MICROPHONE_STATE_CHANGE_NOTIFY', //话筒状态改变通知
    LOGIN_SWITCH_MESSAGE:'LOGIN_SWITCH_MESSAGE', //登录切换指令


}

/**
 *  菜单数据
 * @type {({})[]}
 */
export const menuList = [
    {
        code:1,
        value:'远程协作',
        menuCode:'conversation-websocket',
    },
    {
        code:2,
        value:'知识库',
        menuCode:'knowledge-base-service',
    },
    {
        code:3,
        value:'作业流程',
        menuCode:'site-inspection',
    }
]
