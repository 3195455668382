import Vue from 'vue'
import { Icon, Table, Avatar, Message } from "view-design";
import { reasonDataColumns } from "@/service/device-info-service";
Vue.component('Icon', Icon);
Vue.component('Table', Table);
Vue.component('Avatar', Avatar);
import $event, { EVENT_NAME } from "@/utils/event-emitter";
/**
 * 解决方案模版
 * @type {{ List }}
 */
const Solution = {
    name:'Solution',
    props:{
        /**
         * 故障描述id
         */
        id:{
            type:Number,
        },
        /**
         * Reasons
         */
        reasons:{
            type:Array,
            default:()=>[],
        }
    },
    data() {
        return {
            reasonDataColumns:reasonDataColumns, //可能的原因显示字段
        }
    },
    created() {
        
    },
    methods:{
        /**
         * 编辑可能的原因
         * @param name
         * @param row
         */
        onEditReason(name,row,key) {
            if(this.reasons.length >= 30 && name === 'add'){
                Message.warning('可能原因最多添加30条！');
                return
            }
            let obj = {
                id:this.id,
                name:name,
                reasons:{},
                key:0,
            }
            if(name === 'edit' || name === 'look' || name === 'bigLook'){
                obj.reasons = JSON.parse(JSON.stringify(row))
            }
            if(name === 'bigLook'){
                obj.key = key
            }
            if(name === 'del'){
                obj = {
                    id:this.id,
                    name:name,
                    reasonId:row.id
                }
            }
            $event.$emit(EVENT_NAME.REASON_MODAL_VISIBLE,obj)
        },
        /**
         * 移动排序
         * @param row
         * @param type
         */
        onMoveReason(name,row,type){
            let obj = {
                id:this.id,
                name:name,
                reasonId:row.id,
                moveType:type
            }
            $event.$emit(EVENT_NAME.REASON_MODAL_VISIBLE,obj)
        },
    },
}
export default Solution
