import {  sysncUploadFilesService } from "@/service/system-service";
import { Message } from "view-design";
// import $event, { EVENT_NAME } from "@/utils/event-emitter";

/**
 * 调用同步上传到存储服务器
 * @param files
 * @returns {Promise<void>}
 */
export const onSysncUploadFiles = async (files,type) =>{
    try{
        let params =  new FormData()
        for(let i in files){
            params.append('files',files[i])
        }
        params.append('type',type)
        let config = {
            // onUploadProgress: progressEvent => {
            //     $event.$emit(EVENT_NAME.UPLOAD_PROGRESS_COMPLETE,Math.round((progressEvent.loaded / progressEvent.total ) * 100) -1)
            // },
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        };
        const { code,data } = await sysncUploadFilesService(params,config)
        if(code === 'SUCCESS'){
            return data
        }else{
            Message.error('请求异常，请联系管理员！')
        }
    }catch (error){
        console.log(error)
    }
}
