import  request from '@/utils/request-utils'
import { appConfig } from '@/config/app-config';


const evnUrl = appConfig.domain.httpUrl
const wssUrl = appConfig.domain.wssUrl
export * from './instructions-enum'
export * from './user-enum'
/**
 * post 请求 用户登录换取token
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const userSignInService = async (params) => {
    try {
        params.requestStatus = 'USER_LOGIN'  //添加登录请求临时状态 后期token走headers时去除
        const { data } = await request.postJson(evnUrl.concat('/authentication/user/app/login'),params,'login')
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * get 请求 拉取人员列表
 * @param params
 * @returns {Promise<*>}
 */
export const getPersonnelListService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/conversation/user/realtime/list?size='+params.size+'&page='+params.page))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
/**
 * get 请求 获取存活的会话信息
 * @param params
 * @returns {Promise<*>}
 */
export const getRoomAliveInfoService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/conversation/room/member/list?sessionId='+params.sessionId))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
/**
 * webSocket 地址
 * @type {string}
 */
export const getWssUrl = wssUrl;

/**
 * get 请求 拉取图片上传所需要的参数
 * @param params
 * @returns {Promise<*>}
 */
export const getImageFormDataService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/conversation/resources/session/image'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * post 请求 上传图片
 * @param params
 * @returns {Promise<*>}
 */
export const getImageUpLoadService = async (url,params) => {
    try {
        const { data } = await request.postUpLoad(url,params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * 获取二维码
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const getQRCodeService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/authentication/user/create/loginCode'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * get 请求 获取权限菜单数据
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const getUserSystemMenuList = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/authentication/user/system/power'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * 查询用户获取websocket状态
 * @param params
 * @returns {Promise<*>}
 */
export const getUserWebSocket = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/authentication/user/websocket/login/state?token='+params.token),'','login')
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
/**
 * 发起登录切换
 * @param params
 * @returns {Promise<*>}
 */
export const getUserWebSocketSwitch = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/authentication/user/websocket/login/switch'),params,'login')
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
/**
 * 查询登录结果
 * @param params
 * @returns {Promise<*>}
 */
export const getUserWebSocketResult = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/authentication/user/websocket/login/switch/result?token='+params.token+'&tag='+params.tag))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
