<template>
    <div class="tab-body-box">
        <div class="table" style="display: block">
            <Table ref="selection" :columns="reasonDataColumns" :data="reasons" >
                <template slot-scope="{ index }" slot="序号">
                    {{ index+1 }}
                </template>
                <template slot="操作" slot-scope="{ row }">
                    <a href="javascript:" class="solution-a" @click="onEditReason('look',row)">处理方法（{{ row.solutionList.length }}）</a>
                </template>
            </Table>
        </div>
    </div>
</template>

<script>
import Solution from './solution'
export default Solution
</script>
<style lang="less">
@import "solution";
</style>
