import Vue from 'vue'
import { Icon } from 'view-design';
import {workflowListArray} from "@/service/workflow-service";
Vue.component('Icon', Icon);
/**
 * menu list 工作流菜单列表
 * @type {{ menuList }}
 */
const MenuWorkflow = {
    name:'MenuWorkflow',
    computed: {
    },
    data() {
        return {
            activeStatus:'',
            workflowListArray:workflowListArray,
        }
    },
    created() {
        this.activeStatus = this.$route.path
    },
    methods:{
        /**
         * 跳转
         * @param url
         */
        onLinks(url){
            this.activeStatus = this.$route.path
            console.log(this.activeStatus)
            this.$router.push(url)
        },
    },
    watch: {
        $route: {
            handler() {
                this.activeStatus = this.$route.path;
            },
            deep: true,
        }
    },
    destroyed () {

    }
}
export default MenuWorkflow
