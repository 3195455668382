import  request from '@/utils/request-utils'
import { appConfig } from '@/config/app-config';
const evnUrl = appConfig.domain.httpUrl

export * from './enum'


/**
 * Post Json  请求 同步上传到存储服务器
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const sysncUploadFilesService = async (params,config) => {
    try {
        const { data } = await request.postUpLoadSpeed(evnUrl.concat('/storage/upload/sysncUploadFile'),params,config)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * Post Json  请求 查询资源文件
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const queryFilesInfoService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/storage/upload/queryFileResource'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}


