import  request from '@/utils/request-utils'
import { appConfig } from '@/config/app-config';
const evnUrl = appConfig.domain.httpUrl
export * from './enum'
/**
 * get 请求 获取所有设备分类
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const getDeviceClassAllService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/knowledge/equipment/classification/list/all'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
