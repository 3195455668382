/**
 * hader
 * @type {{}}
 */
import Vue from "vue";
import {Avatar,Dropdown,Button,Message,Modal} from 'view-design';
import { mapState} from "vuex";
Vue.component('Avatar', Avatar);
Vue.component('Dropdown', Dropdown);
Vue.component('Button', Button);
import Cookies from "js-cookie";
import {getQRCodeService, getUserSystemMenuList, menuList} from '@/service/user-service'
import { queryFilesInfoService } from '@/service/system-service'
import defaultAvatar from '@/assets/Image/default-avatar.png'
import vueQr from 'vue-qr'
import $event, {EVENT_NAME} from "@/utils/event-emitter";
import {systemPublishEnum, systemPublishLogo} from "@/service/system-service";
const Header = {
    name:'Header',
    components: {
        vueQr
    },
    computed: {
        ...mapState({
            state: state => state.userCenter.userInfo, //获取状态管理用户中心
        }),
    },
    data() {
        return {
            systemPublishMenu:systemPublishEnum, //配置演示状态枚举
            systemPublishLogo:systemPublishLogo, //配置演示状态
            defaultAvatar:defaultAvatar,
            linkUrl:process.env.NODE_ENV,
            logoUrl:'',
            QRCode:'',
            logoSrc:require("@/assets/Icon/logo-img.png"),
            menuList:[],
            headerMenuId:1,
        }
    },
    created() {
        this.onUserSystemMenuList().then()
        if(this.state.softwareLogo === ''){
            this.logoUrl = require("@/assets/Logo/menu-logo.png")
        }else{
            this.onImageUrl(this.state.softwareLogo)
        }
        /**
         * 修改顶部菜单
         */
        $event.$on(EVENT_NAME.HEADER_MENU_TAB, (id) => {
            this.headerMenuId = id
        })
    },
    methods: {
        /**
         * 调用二维码请求
         */
        onModalCode() {
            this.onQRCode().then()
        },
        /**
         * 修改顶部tab值
         * @param code
         */
        onHeaderMenu(code){
            this.headerMenuId = code
            $event.$emit(EVENT_NAME.HEADER_MENU_TAB,code)
            if(code === 1){
                this.$router.push('/')
            }
            if(code === 2){
                this.$router.push('/device/list')
            }
            if(code === 3){
                this.$router.push('/workflow/task/inspection/list')
            }
        },
        /**
         * 退出登录
         */
        onSignOut() {
            Modal.confirm({
                title: '提示',
                content: '<p>您确定要退出系统吗？</p>',
                onOk: () => {
                    // Cookies.remove('token');
                    Cookies.remove('token');
                    this.$router.push('/login').then()
                }
            });
        },
        /**
         * 获取二维码
         * @returns {Promise<void>}
         */
        async onQRCode() {
            try {
                const data  = await getQRCodeService('');
                if(data.code === 'SUCCESS'){
                    this.QRCode = data.data
                }else{
                    Message.error('获取失败！')
                }
            }catch (error) {
                console.log(error)
            }
        },
        /**
         * 获取权限菜单数据
         */
        async onUserSystemMenuList() {
            try {
                let params = {}
                const { code,data} = await getUserSystemMenuList(params)
                if(code === 'SUCCESS'){
                    this.menuList = []
                    for(let i in data){
                        for(let j in  menuList){
                            if(data[i].moduleCode === menuList[j].menuCode){
                                this.menuList.push(menuList[j])
                            }
                        }
                    }
                    this.menuList.map((item, index) => {
                        if (item.menuCode == 'conversation-websocket') {
                            this.menuList.unshift(this.menuList.splice(index, 1)[0]);
                        }
                    });
                    this.onHeaderMenu(this.menuList[0].code)
                }else{
                    Message.error('请求异常，请联系管理员！')
                }
            }catch (error){
                console.log(error)
            }
        },
        /**
         * 拉取资源图片
         * @returns {Promise<void>}
         */
        async onImageUrl(id) {
            try {
                let params = {
                    resourceName:id,
                }
                const {code,data} = await queryFilesInfoService(params);
                if(code === 'SUCCESS'){
                    this.logoUrl = data.url
                }else{
                    Message.error('拉取上传参数出错，请稍后再试！')
                }
            }catch (error) {
                console.log(error)
            }
        },
    },
    destroyed () {
        $event.$off([EVENT_NAME.HEADER_MENU_TAB,])
    }
}
export default Header
