/**
 *  全局标记组件
 */
import Vue from 'vue/dist/vue.esm.js'
const defaults = {
    duration: 1000, // 显示的时间 ms
    animateTime: 1000, // 动画时间,表示这个组件切换show的动画时间
}
function getSignInstance (options) {
    let duration = defaults.duration
    let dom = document.getElementById('videoHandle')
    let VueSign = Vue.extend({
        data() {
            return {
                offsetLeft:options.offsetLeft,
                offsetTop:options.offsetTop,
                color:options.color,
                signName:options.signName,
                width:dom.offsetWidth,
                height:dom.offsetHeight
            }
        },
        template:`<div class="video-drop" :style="'left:'+(offsetLeft-40)+'px;top:'+(offsetTop-40)+'px'">
        <div :style="'position: absolute;'+(color === '#FFF100'?'color:#515a6e':'color:#fff')+';border-radius: 4px;padding:0 2px;'+((offsetLeft+80)>width?'left:0':'right:0')+';'+((offsetTop-80)>=0?'top:0':'bottom:0')+';background:'+color+';'">{{ signName }}</div>
        <div class="point"><i class="point-10" :style="'border-color:'+color+';box-shadow: inset 0 0 5em '+color+';'"></i></div>
        <div class="point"><i class="point-40" :style="'border-color:'+color+';box-shadow: inset 0 0 5em '+color+';'"></i></div>
        <div class="point"><i class="point-70" :style="'border-color:'+color+';box-shadow: inset 0 0 5em '+color+';'"></i></div>
        </div>`
    })
    let newSign = new VueSign()
    let vm = newSign.$mount()
    let el = vm.$el
    document.getElementById('videoDrop').appendChild(el) // 把生成的提示的dom插入body中
    let t1 = setTimeout(() => {
        clearTimeout(t1)
        let t2 = setTimeout(() => {
            clearTimeout(t2)
            document.getElementById('videoDrop').removeChild(el) //从body中移除dom
            newSign.$destroy()
            vm = null // 设置为null，好让js垃圾回收算法回收，释放内存
        }, defaults.animateTime)
    }, duration)
}
export default {
    name:'Sign',
    info (options){
        if (!options) return
        getSignInstance(options)
    },
}
