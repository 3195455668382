<template>
    <div class="menu-body">
        <div class="menu-member-top" v-if="menuType === 'memberList'">
            <span class="title">联系人({{ onlineListObj.length }})</span>
            <div class="menu-member-search">
                <div class="search-box">
                    <span class="icon"><Icon @click="onMenuSearch" type="ios-search"  size="22"/></span>
                    <input type="text" v-model="searchContent" maxlength="10" @keyup="onMenuSearch" :placeholder="searchPlaceholder" class="input" @focus="onFocus" @blur="onBlur"/>
                </div>
            </div>
        </div>
        <div class="menu-session-member-top" v-if="menuType === 'sessionMemberList'">
            <div class="menu-contacts">邀请成员<a href="javascript:" @click="onSessionVisible"><Icon type="md-close" /></a></div>
            <div class="menu-search">
                <div class="search-box">
                    <span class="icon"><Icon @click="onMenuSearch" type="ios-search"  size="22"/></span>
                    <input type="text" v-model="searchContent" maxlength="10" @keyup="onMenuSearch" :placeholder="searchPlaceholder" class="input" @focus="onFocus" @blur="onBlur"/>
                </div>
            </div>
        </div>
        <div class="menu-list">
            <ul>
                <li v-for="(onlineList,key) in onlineListObj" :key="key">
                    <div class="head">
                        <Avatar :class="onlineList.status === onLineStatus.OFFLINE?'menu-grey':''" shape="square" :src="onlineList.userProfilePictureUrl || defaultAvatar" icon="ios-person"/>
                        <span class="shape pc" v-if="onlineList.status !== onLineStatus.OFFLINE && onlineList.onlineTerminal==='PC'" :class="onlineList.status === onLineStatus.BUSY?'be-busy':'on-line'"></span>
                        <span class="shape glasses" v-if="onlineList.status !== onLineStatus.OFFLINE && onlineList.onlineTerminal==='AR'" :class="onlineList.status === onLineStatus.BUSY?'be-busy':'on-line'"></span>
                        <span class="shape phone" v-if="onlineList.status !== onLineStatus.OFFLINE && onlineList.onlineTerminal!=='PC' && onlineList.onlineTerminal!=='AR'" :class="onlineList.status === onLineStatus.BUSY?'be-busy':'on-line'"></span>
                        <span class="off-line" v-if="onlineList.status === onLineStatus.OFFLINE"></span>
                    </div>
                    <div class="title">
                        <font>{{ onlineList.name}}</font>
        <!--              &#12288;|&#12288;部门&#8194;职务-->
                    </div>
                    <div class="status">
                        <font v-if="onlineList.status === onLineStatus.ONLINE">在线</font>
                        <font v-if="onlineList.status === onLineStatus.BUSY">忙碌</font>
                        <font v-if="onlineList.status === onLineStatus.OFFLINE">最近登录&#12288;{{ onlineList.lastOnlineTime }}</font>
                    </div>
                    <a href="javascript:" class="launch" v-if="onlineList.status === onLineStatus.ONLINE" @click="onLaunch(onlineList)"></a>
                </li>
            </ul>
            <span class="menu-text" v-if="onlineListObj.length === 0">暂无人员</span>
        </div>
    </div>
</template>

<script>
import MenuList from './index'
export default MenuList
</script>
<style lang="less">
@import "index";
</style>
