/**
 * 来电or发起
 * @type {{}}
 */
import Vue from "vue";
import {Avatar} from 'view-design';
Vue.component('Avatar', Avatar);
import defaultAvatar from '@/assets/Image/default-avatar.png'
const Called = {
    name:'Called',
    props: {
        /**
         * 监听传过来的model值
         */
        value: {
            type: Boolean,
            default: false
        },
        /**
         * 监听传过来的info值
         */
        calledInfo: {
            type:Object,
            default: ''
        },
        /**
         * 监听传过来的发起以及取消
         * 发起：1，接收：2
         */
        status:{
            type:Number
        }
    },
    data() {
        return {
            visibleCalled:this.value,
            defaultAvatar:defaultAvatar,
            callTime:5,
            intervalId:null,
        }
    },
    methods: {
        /**
         * 拒绝回调
         */
        onCalledHangUp() {
            this.visibleCalled = false;
            this.$emit('input', false);
            this.$emit('on-hang-up');
            this.clearInterval()
        },
        /**
         * 接听回调
         */
        onCalledAnswer(){
            this.visibleCalled = false;
            this.$emit('input', false);
            this.$emit('on-answer');
            this.clearInterval()
        },
        /**
         * 取消回调
         */
        onCancel() {
            this.visibleCalled = false;
            this.$emit('input', false);
            this.$emit('on-cancel');
        },
        /**
         * 开始计时
         */
        startTime(){
            this.intervalId = setInterval(()=>{
                this.callTime--
                if(this.callTime===0){
                    this.onCalledAnswer()
                    this.clearInterval()
                }
            },1000)
        },
        /**
         * 清除计时
         */
        clearInterval() {
            this.callTime = 5
            clearInterval(this.intervalId)
        },
    },
    watch: {
        value(val) {
            this.visibleCalled = val;
            if(val){
                this.startTime()
            }else{
                this.clearInterval()
            }
        },
    }
}
export default Called
