/**
 * 设备信息服务
 * 生产厂家 / 设备型号
 */
import  request from '@/utils/request-utils'
import { appConfig } from '@/config/app-config';
const evnUrl = appConfig.domain.httpUrl
export * from './enum'
/**
 * get 请求 获取所有设备型号
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const getDeviceModelService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/knowledge/equipment/model/list/all'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
/**
 * get 请求 获取所有生产厂家
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const getManufacturerService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/knowledge/equipment/manufacturer/list/all'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * post 请求 添加设备型号
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const addDeviceModelService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/knowledge/equipment/model/add'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * post 请求 添加生产厂家
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const addManufacturerService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/knowledge/equipment/manufacturer/add'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
/**
 * get 请求 根据设备型号名称查询生产厂家
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const getNameManufacturerService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/knowledge/equipment/manufacturer/list/name?name='+params.name))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
/**
 * get 请求 根据设备型号Id查询生产厂家
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const getIdManufacturerService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/knowledge/equipment/manufacturer/list?modelId='+params.id))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * post 请求 添加设备信息
 * @param formData
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const addDeviceInfoService = async (params) => {
    try {
        const { data } = await request.postUpLoad(evnUrl.concat('/knowledge/equipment/add'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
/**
 * post 请求 修改设备信息
 * @param formData
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const updateDeviceInfoService = async (params) => {
    try {
        const { data } = await request.postUpLoad(evnUrl.concat('/knowledge/equipment/update'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * get 请求 查询设备列表
 * @param formData
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const getDeviceListService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/knowledge/equipment/list?equipmentClassificationId='+params.deviceType+'&manufacturerId='+params.manufacturer+'&name='+params.search+'&size='+params.displayNumber+'&page='+params.currentPage))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}


/**
 * post 请求 删除设备信息
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const delDeviceInfoService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/knowledge/equipment/delete'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * get 请求 查询设备信息
 * @param formData
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const getDeviceInfoService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/knowledge/equipment/materia/query/info?id='+params.id+'&dataType='+params.dataType+'&partsId='+params.partsId))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * get 请求 通过id查询设备信息
 * @param formData
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const getDeviceInfoIdService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/knowledge/equipment/id?id='+params.id))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
/**
 * get 请求 通过id查询结构部件数量信息
 * @param formData
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const getStructuralInfoIdService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/knowledge/equipment/parts/id'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * get 请求 根据设备id获取结构部件列表
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const getStructuralDeviceIdService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/knowledge/equipment/parts/list?id='+params.id))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * post 请求 添加资料文件
 * @param formData
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const addDeviceDataService = async (params) => {
    try {
        const { data } = await request.postUpLoad(evnUrl.concat('/knowledge/equipment/materia/add'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * post 请求 删除资料文件
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const delDeviceDataService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/knowledge/equipment/materia/delete'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * post 请求 重新上传资料
 * @param formData
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const updateDeviceDataService = async (params) => {
    try {
        const { data } = await request.postUpLoad(evnUrl.concat('/knowledge/equipment/materia/reupload'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * post 请求 查询故障分析
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const getFailureListService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/knowledge/equipment/failure/list?id='+params.id))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * post 请求 添加故障分析
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const addFailureService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/knowledge/equipment/failure/add'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * get 请求 查询设备资源列表
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const getDeviceTypeListService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/knowledge/equipment/materia/list?id='+params.id+'&type='+params.type+'&size='+params.size+'&page='+params.page))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}


/**
 * post 请求 修改电气列表名称
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const updateElectricListNameService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/knowledge/equipment/electric/atlas/updateName'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * post 请求 修改电气图册状态
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const updateElectricStatusService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/knowledge/equipment/electric/atlas/publish'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
/**
 * post 请求 根据id获取结构部件信息
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const getStructuralIdService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/knowledge/equipment/parts/id'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
/**
 * post 请求 删除电气图册
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const delElectricalListService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/knowledge/equipment/electric/atlas/delete'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
/**
 * post 请求 获取电气图册信息
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const getElectricalInfoService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/knowledge/equipment/electric/atlas/detail'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * post 请求 编辑电气图册数据
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const editElectricalService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/knowledge/equipment/electric/atlas/edit'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
