/**
 * 上传类型
 * @type {{}}
 */
export const systemUploadType = {
    knowledge_image:'knowledge_image', //知识库图片
    conversation_session_image:'conversation_session_image', //远程指导图片
    inspection_node_image:'inspection_node_image', //安全巡检节点图片
    inspection_results_image:'inspection_results_image', //安全巡检结果图片
    inspection_results_voice:'inspection_results_voice', //安全巡检结果语音
    inspection_results_video:'inspection_results_video', //安全巡检结果录像
    user_avatar_image:'user_avatar_image', //用户头像图片
    system_app:'system_app', //系统app版本
}

/**
 * 演示配置
 * @type {{DEMONSTRATE: string, NORMAL: string}}
 */
export const systemPublishEnum = {
    DEMONSTRATE:'DEMONSTRATE', // 演示无logo
    NORMAL:'NORMAL', //正常有logo
}

/**
 * 配置演示状态
 * @type {string}
 */
export const systemPublishLogo = systemPublishEnum.DEMONSTRATE
