<template>
    <div class="workflow-box">
        <div class="list-item" :key="'child1'+key" v-for="(workflowList,key) in workflowListArray">
            <div class="item-thead">
                <a href="javascript:"  @click="workflowList.visible = !workflowList.visible">
                    <Icon :type="workflowList.visible?'md-arrow-dropdown':'md-arrow-dropright'" class="icon" /> {{ workflowList.name }}
                </a>
            </div>
            <div class="item-body" v-if="workflowList.visible">
                <ul>
                    <li :key="'child2'+key" v-for="(children,key) in workflowList.children" :class="activeStatus === children.url || activeStatus === children.info?'active':''">
                        <a href="javascript:" @click="onLinks(children.url)">
                            <Icon v-if="children.icon === 'task-inspection'" :custom="activeStatus === children.url || activeStatus === children.info?'icon-Inspection-blue':'icon-Inspection-white'" class="icon"/>
                            <Icon v-if="children.icon === 'task-guidance'" :custom="activeStatus === children.url || activeStatus === children.info?'icon-guidance-blue':'icon-guidance-white'" class="icon"/>
                            <Icon v-if="children.icon === 'record-inspection' || children.icon === 'record-guidance'" :custom="activeStatus === children.url || activeStatus === children.info?'icon-record-blue':'icon-record-white'" class="icon"/>{{ children.name }}
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import MenuWorkflow from './index'
export default MenuWorkflow
</script>
<style lang="less">
@import "index";
</style>
