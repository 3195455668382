/**
 *  全局聊天组件
 */
import Vue from 'vue/dist/vue.esm.js'
import $event, {EVENT_NAME} from "@/utils/event-emitter";
const defaults = {
    duration: 8000, // 显示的时间 ms
    animateTime: 1000, // 动画时间,表示这个组件切换show的动画时间
}
function getChatInstance (options) {
    let duration = defaults.duration
    let VueChat = Vue.extend({
        data() {
            return {
                name:options.name,
                content:options.content,
                show: this.show,
                color:options.color,
                type: options.type
            }
        },
        methods:{
            /**
             * 发送图片放大事件
             * @param src
             */
            onImageEnlarge(src){
                $event.$emit(EVENT_NAME.IMAGE_ENLARGE,src);
            }
        },
        template:`
            <div class="news" :class="!show?'yb-fadeoutT':'yb-bouncein'">
                <div class="news-content">
                    <label :style="'color:'+color">{{ name }}：</label>
                    <b v-if="type==='text'">{{ content }}</b>
                    <span v-if="type==='image'"><img @click="onImageEnlarge(content)" :src="content"></span>
                </div>
            </div>`
    })
    let newChat = new VueChat()
    let vm = newChat.$mount()
    let el = vm.$el
    document.getElementById('videoChat').appendChild(el) // 把生成的提示的dom插入body中
    vm.show = true
    let t1 = setTimeout(() => {
        clearTimeout(t1)
        vm.show = false
        let t2 = setTimeout(() => {
            clearTimeout(t2)
            document.getElementById('videoChat').removeChild(el) //从body中移除dom
            newChat.$destroy()
            vm = null // 设置为null，好让js垃圾回收算法回收，释放内存
        }, defaults.animateTime)
    }, duration)
}
export default {
    name:'Chat',
    info (options){
        if (!options) return
        getChatInstance(options)
    },
}
