// import line0  from '@/assets/Hold/line0.svg'
// import line1  from '@/assets/Hold/line1.svg'
// import line2  from '@/assets/Hold/line2.svg'
// import line3  from '@/assets/Hold/line3.svg'
/**
 * 登录验证提示语
 * @type {{USERNAME_OR_PASSWORD_FREE_ERROR: string}}
 */
export const LOGIN_CHECK_MESSAGE = {
    USERNAME_OR_PASSWORD_FREE_ERROR:'帐号或密码错误！', //登录验证提示
    LOGIN_SUCCESSFUL:'欢迎登录禹步信息live平台！',
}
/**
 * 登录方式状态验证
 * @type {{ACCOUNT_MODE: string, VERIFICATION_CODE_MODE: string}}
 */
export const LOGIN_MODE = {
    ACCOUNT_MODE:'ACCOUNT_MODE',  //账户登录
    VERIFICATION_CODE_MODE:'VERIFICATION_CODE_MODE' //验证码登录
}

/**
 * footer 显示状态
 * @type {{Object}}
 */
export const USER_BAR_STATUS = {
    CONTACTS:'CONTACTS', //联系人
    CHAT_RECORD:'CHAT_RECORD', //聊天记录
    ME:'ME',  //我的
}

/**
 * 在线状态
 */
export const ONLINE_STATUS = {
    ONLINE:'ONLINE',  //在线
    OFFLINE:'OFFLINE',//离线
    BUSY:'BUSY', //忙碌
    CALLING:'CALLING', //呼叫中
    CONVERSATION:'CONVERSATION', //会话中
    UNKNOWN:'UNKNOWN', //未知
}

/**
 * 线条
 */
export const lineStroke = [
    {
        stroke:1,
    },
    {
        stroke:2,
    },
    {
        stroke:3,
    },
    {
        stroke:4,
    }
]
/**
 * 拾色器
 * @param name
 */
export const colorPicker = [
    {
        name:'green',
        color:'#33E633'
    },
    {
        name:'yellow',
        color:'#FFF100'
    },
    {
        name:'red',
        color:'#E60012'
    },
    {
        name:'blue',
        color:'#00A0E9'
    },
]




