import Vue from 'vue'
import { Avatar } from 'view-design';
Vue.component('Avatar', Avatar);
import $event,{ EVENT_NAME } from '@/utils/event-emitter';
import {getPersonnelListService, ONLINE_STATUS} from "@/service/user-service";
import defaultAvatar from '@/assets/Image/default-avatar.png'
/**
 * menu list 菜单列表
 * @type {{ MenuList }}
 */
const MenuList = {
    name:'MenuList',
    props:{
        /**
         * 菜单类型（memberList：成员列表；sessionMemberList：会话成员列表）
         */
        menuType:{
            type:String,
            default:''
        },
        /**
         * 监听传过来的model值
         */
        value: {
            type: Object,
            default: false
        },
    },
    data() {
        return {
            searchPlaceholder:'搜索', //搜索 Placeholder
            searchContent:'', //搜索内容
            onlineListObj:[],
            onlineBustObj:[],
            onLineStatus:ONLINE_STATUS,
            defaultAvatar:defaultAvatar,
            onListObj:[],
        }
    },
    created() {
        this.onPersonnelList().then();
    },
    methods:{
        /**
         * 获取焦点清空
         */
        onFocus(){
            this.searchPlaceholder = ''
        },
        /**
         * 失去焦点后复制
         */
        onBlur(){
            this.searchPlaceholder = '搜索'
        },
        /**
         * 关闭会话成员列表
         */
        onSessionVisible() {
            this.$emit('on-session-visible')
        },
        /**
         * 搜索成员
         */
        onMenuSearch() {
            if(this.searchContent == ''){
                this.onMenuSort(this.onListObj)
            }else{
                this.onMenuSort(this.onFilter(this.searchContent))
            }
        },
        /**
         * 过滤列表相同名称
         * @param content
         * @returns {*[]}
         */
        onFilter(content){
            return this.onListObj.filter((value)=>{  //过滤数组元素
                return value.name.includes(content); //如果包含字符返回true
            });
        },
        onLaunch(obj){
            $event.$emit(EVENT_NAME.COOPERATION_USER,obj)
            this.searchContent = ''
        },
        /**
         * 拉取人员列表
         * @returns {Promise<void>}
         */
        async onPersonnelList() {
            try {
                let params = {
                    size:9999,
                    page:1
                }
                const { data } = await getPersonnelListService(params);
                this.onMenuSort(data.content)
                this.onListObj = data.content

            }catch (error) {
                console.log(error)
            }
        },
        /**
         * 列表排序
         * @param onList
         */
        onMenuSort(onList){
            let offList = [];
            this.onlineBustObj = []
            this.onlineListObj = []
            for(let i in onList){
                if(onList[i].status === 'ONLINE'){
                    this.onlineListObj.push(onList[i])
                }else if(onList[i].status ==='BUSY'){
                    this.onlineBustObj.push(onList[i])
                }else{
                    offList.push(onList[i])
                }
            }
            this.onlineListObj = this.onlineListObj.concat(this.onlineBustObj,offList)
            this.$emit('contacts-number',this.onlineListObj.length)
        },
    },
    watch: {
        value(obj) {
            for(let i in this.onListObj){
                if(this.onListObj[i].uid === obj.uid){
                    this.onListObj[i].status = obj.status
                }
            }
            // this.onPersonnelList().then();
            this.onMenuSort(this.onListObj)
        },
    },
    destroyed () {
        $event.$off([EVENT_NAME.USERS_STATUS_CHANGE_NOTICE,EVENT_NAME.SORT_MENU_LIST])
    }
}
export default MenuList
