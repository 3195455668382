<template>
    <div class="yb-live-called yb-fadeinB" v-if="visibleCalled">
        <Avatar shape="square" :src="calledInfo.userProfilePictureUrl || defaultAvatar" class="avatar" icon="ios-person"/>
        <p class="called-text" v-if="status === 1">  正在为您接通“{{ calledInfo.name }}” ，请稍等…</p>
        <p class="called-text" v-if="status === 2"> “{{ calledInfo.name }}” 邀请你加入远程协作</p>
        <div class="called-btn">
            <Button v-if="status === 1" type="error" long @click="onCancel" class="btn-refuse"><Icon type="ios-call" />取消</Button>
            <Button v-if="status === 2" type="error" long @click="onCalledHangUp" class="btn-refuse"><Icon type="ios-call" />拒绝</Button>
            <Button v-if="status === 2" long @click="onCalledAnswer" class="btn-accept"><Icon type="ios-call" />接受{{ callTime }}</Button>
        </div>
    </div>
</template>
<script>
import Called from './called'
export default Called
</script>
<style lang="less">
@import "called";
</style>
